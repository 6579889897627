<template>
  <div>
    Sales - Velocity / Trends
  </div>
</template>

<script>
export default {
  name: 'SalesVelocityTrends',
}
</script>

<style scoped>

</style>
